import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-8 gap-5 p-10 px-40" }
const _hoisted_2 = { class: "col-span-5 shadow-md py-4 px-6 border rounded-2xl" }
const _hoisted_3 = {
  key: 0,
  class: "col-span-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["TenantRegistrationForm"], {
        "is-disable": true,
        "data-tenant-registration": $setup.tenantData
      }, null, 8, ["data-tenant-registration"])
    ]),
    ($props.isShowStatusRemark)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["StatusCard"], { status: $setup.formStatus }, null, 8, ["status"]),
          _createVNode($setup["RemarkCard"], {
            remark: $setup.tenantData?.RemarkContent
          }, null, 8, ["remark"])
        ]))
      : _createCommentVNode("", true)
  ]))
}