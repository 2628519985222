import { ICompanyDTO, IFileDTO } from "@/models/Company";
import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { CompanyManagementAPI } from "@/services/company";
import { getTenantId } from "@/utils/storage";
import { ICreateTenantResponse, ITenantParameters } from "@/models/Tenant";

const name = "CompanyModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class CompanyModule extends VuexModule {
  private errorDataCompany = {} as ErrorResponse;
  private errorCreateDataCompany = {} as ErrorResponse;
  private errorUpdateDataCompany = {} as ErrorResponse;
  private errorPushFiles = {} as ErrorResponse;

  private errorGetFiles = {} as ErrorResponse;
  private dataFiles = [] as any;
  private dataListCompanies = {
    companyForm: [] as any,
  } as any;
  private isLoadingDataCompany = false;

  get getDataListCompaniesGetter() {
    return this.dataListCompanies;
  }
  get isLoadingDataCompanyGetter() {
    return this.isLoadingDataCompany;
  }

  get getDataFilesGetter() {
    return this.dataFiles;
  }

  @Mutation
  setLoadingGetDataCompany(loading: boolean) {
    this.isLoadingDataCompany = loading;
  }
  @Mutation
  getDataCompanySuccess(data: any) {
    this.errorDataCompany = {} as ErrorResponse;
    data.forEach((items: any) => {
      if (items.PhoneNumber) {
        items.phoneCode = items.PhoneNumber.split("-")[0];
        items.PhoneNumber = items.PhoneNumber.split("-")[1];
      }
    });
    this.dataListCompanies.companyForm = data;
    this.isLoadingDataCompany = false;
  }
  @Mutation
  getDataCompanyFailure(error: ErrorResponse) {
    this.errorDataCompany = error;
    this.isLoadingDataCompany = false;
  }
  @Action({ rawError: true })
  async getDataCompany() {
    this.setLoadingGetDataCompany(true);
    const servicesCompanyAPI = new CompanyManagementAPI();
    const { error, data } = await servicesCompanyAPI.getDataCompany();

    const tenantId = getTenantId();

    if (error) {
      this.getDataCompanyFailure(error);
    } else {
      Promise.all(
        data.map((itemCompany: any) =>
          this.getFiles({
            TenantId: tenantId ?? "",
            CompanyProfileId: itemCompany.Id,
          })
        )
      );
      this.getDataCompanySuccess(data);
    }
  }

  @Mutation
  createDataCompanySuccess() {
    ElMessage({
      message: "Successfully create companies!",
      grouping: true,
      type: "success",
    });
    this.errorCreateDataCompany = {} as ErrorResponse;
  }
  @Mutation
  createDataCompanyFailure(error: ErrorResponse) {
    ElMessage({
      message: "Create companies fail",
      grouping: true,
      type: "error",
    });
    this.errorCreateDataCompany = error;
  }
  @Action({ rawError: true })
  async createDataCompany(payload: ICompanyDTO) {
    const servicesCompanyAPI = new CompanyManagementAPI();
    const { error, data } = await servicesCompanyAPI.createDataCompany(payload);

    if (error) {
      this.createDataCompanyFailure(error);
    } else {
      this.createDataCompanySuccess();
      const listFiles = [] as any;

      if (payload.FileUpload) {
        payload.FileUpload.forEach(async (items: any) => {
          const formData = new FormData();
          formData.append("file", items.raw);
          listFiles.push(this.pushFile({ Id: data.Id, File: formData }));
        });
        await Promise.all(listFiles);
      }
    }
  }

  @Mutation
  createDataCompanyRegisterSuccess() {
    this.errorCreateDataCompany = {} as ErrorResponse;
  }
  @Mutation
  createDataCompanyRegisterFailure(error: ErrorResponse) {
    ElMessage({
      message: "Create companies fail",
      grouping: true,
      type: "error",
    });
    this.errorCreateDataCompany = error;
  }
  @Action({ rawError: true })
  async createDataRegisterCompany(payload: ICompanyDTO) {
    const servicesCompanyAPI = new CompanyManagementAPI();
    const { error, data } = await servicesCompanyAPI.createDataRegisterCompany(
      payload
    );

    if (error) {
      this.createDataCompanyRegisterFailure(error);
    } else {
      this.createDataCompanyRegisterSuccess();
      const listFiles = [] as any;

      if (payload.FileUpload) {
        payload.FileUpload.forEach(async (items: any) => {
          const formData = new FormData();
          formData.append("file", items.raw);
          formData.append("DocType", "0");
          listFiles.push(
            this.pushFileRegisterCompany({
              Id: data.Id,
              File: formData,
              TenantId: payload.TenantId,
            })
          );
        });
      }
      if (payload.FileLetter) {
        payload.FileLetter.forEach(async (items: any) => {
          const formData = new FormData();
          formData.append("file", items.raw);
          formData.append("DocType", "1");
          listFiles.push(
            this.pushFileRegisterCompany({
              Id: data.Id,
              File: formData,
              TenantId: payload.TenantId,
            })
          );
        });
      }
      await Promise.all(listFiles);
    }
  }
  @Mutation
  updateDataCompanySuccess() {
    this.errorUpdateDataCompany = {} as ErrorResponse;
    ElMessage({
      message: "Successfully updated company information!",
      grouping: true,
      type: "success",
    });
  }
  @Mutation
  updateDataCompanyFailure(error: ErrorResponse) {
    this.errorUpdateDataCompany = error;
    ElMessage({
      message: "Update company information fail",
      grouping: true,
      type: "error",
    });
  }
  @Action({ rawError: true })
  async updateDataCompany(payload: ICompanyDTO) {
    const servicesCompanyAPI = new CompanyManagementAPI();
    const { error } = await servicesCompanyAPI.updateDataCompany(payload);

    if (error) {
      this.updateDataCompanyFailure(error);
    } else {
      this.updateDataCompanySuccess();
      const listFiles = [] as any;

      if (payload.FileUpload) {
        payload.FileUpload.forEach(async (items: any) => {
          const formData = new FormData();
          formData.append("file", items.raw);
          listFiles.push(this.pushFile({ Id: payload.Id, File: formData }));
        });
        await Promise.all(listFiles);
      }
    }
  }

  @Mutation
  pushFileSuccess() {
    this.errorPushFiles = {} as ErrorResponse;
  }
  @Mutation
  pushFileFailure(error: ErrorResponse) {
    this.errorPushFiles = error;
  }
  @Action({ rawError: true })
  async pushFile(payload: IFileDTO) {
    const servicesCompanyAPI = new CompanyManagementAPI();
    const { error } = await servicesCompanyAPI.pushFile(payload);

    if (error) {
      this.pushFileFailure(error);
    } else {
      this.pushFileSuccess();
    }
  }

  @Action({ rawError: true })
  async pushFileRegisterCompany(payload: IFileDTO) {
    const servicesCompanyAPI = new CompanyManagementAPI();
    const { error } = await servicesCompanyAPI.pushFileRegisterCompany(payload);

    if (error) {
      this.pushFileFailure(error);
    } else {
      this.pushFileSuccess();
    }
  }
  @Mutation
  getFilesSuccess(data: any) {
    this.errorGetFiles = {} as ErrorResponse;
    this.dataFiles = data;
    if (!isEmpty(data)) {
      const company = this.dataListCompanies.companyForm.find(
        (item: any) => item.Id === data[0].ModelId
      );

      company.File = data;
    }
  }

  @Mutation
  getFilesNewSuccess(data: any) {
    this.errorGetFiles = {} as ErrorResponse;
    this.dataFiles = data;
    this.isLoadingDataCompany = false;
  }

  @Mutation
  getFilesFailure(error: ErrorResponse) {
    this.errorGetFiles = error;
    this.isLoadingDataCompany = false;
  }
  @Action({ rawError: true })
  async getFiles(params: ICreateTenantResponse) {
    this.setLoadingGetDataCompany(true);
    const servicesCompanyAPI = new CompanyManagementAPI();
    const { data, error } = await servicesCompanyAPI.getFile(params);

    if (error) {
      this.getFilesFailure(error);
    } else {
      this.getFilesNewSuccess(data);
    }
  }
}

export default getModule(CompanyModule);
